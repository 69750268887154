import React from "react";
import { graphql } from "gatsby";

import TermlyPage from "~/components/TermlyPage";

const WebsiteTermsOfUse = ({ data, location }) => {
  return <TermlyPage data={data} location={location} />;
};

export const query = graphql`
  {
    contentfulPage(slug: { eq: "website-terms-of-use" }) {
      title
      termlyScriptUrl
      termlyId
    }
  }
`;

export default WebsiteTermsOfUse;
